@import '../../style/main.scss';

main.homepage {
    padding-top: 30px;
    padding-bottom: 0;
    margin-bottom: 0;
    
    .mainVisuel {
        width: 100%;
    }

    .bottomHomepage {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: column;
            align-items: center;

            picture {
                align-self: flex-start;
                
                img {
                    position: fixed;
                    top: 0;
                    height: 100%;
                    width: auto;
                }
            }

            .slick-slider {
                width: 50%;
                margin-left: auto;
                position: absolute;
                right: 0;
                bottom: 25%;

                @include desktop {
                    position: relative;
                    margin-top: 100px;
                }
            }
        }
    }

    .titleLink {
        @include desktop {
            width: 50%;
            margin-right: 0;
            display: inline-block;
            margin-left: 50%;
            margin-top: 20px;

            h1 {
                font-size: 160px;
            }
        }
    }


    section.footer {
        margin-top: 150px;

        @include desktop {
            width: 50%;
            margin-left: 50%;
        }
    }
}