@import '../../style/main.scss';

main.track {
    padding-top: 30px;
    
    .lyrics {
        margin-top: 30px;

        p {
            margin: auto;
            margin-bottom: 20px;
            text-align: center;
            max-width: 100%;
            font-size: 14px;
            position: relative;
            line-height: 20px;
            z-index: 6;
            max-width: 80%;

            @include desktop {
                max-width: 500px;
            }
        }
    }

    h2 {
        text-align: center;
        margin: auto;
        margin-top: 40px;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        // max-width: 80%;
    }

    .credits {
        font-style: italic;
        margin: auto;
        margin-bottom: 20px;
        text-align: center;
        max-width: 100%;
        font-size: 10px;
        line-height: 16px;
        max-width: 80%;
        width: 400px;
    }

    iframe {
        margin: 0 auto;
        margin-top: 50px;
        display: block;
        position: relative;
        z-index: 5;
        max-width: 90%;
    }

    .amirProfil1 {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 120px;
        opacity: .4;

        @include desktop {
            left: 100px;
            bottom: 2%;
            width: auto;
            bottom: 2%;
            opacity: 1;
            max-width: 20%;
            height: auto;
            max-height: 85%;
        }
    }

    .amirProfil2 {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 300px;
        display: none;

        @include desktop {
            display: initial;
        }
    }
}