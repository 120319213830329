.trackSocialMedia {
    margin: auto;
    margin-top: 30px;

    ul {
        display: flex;
        margin: auto;
        justify-content: center;

        li {
            margin: 0 5px;

            img {
                height: 24px;
                width: auto;
                transition: .2s all ease-in-out;

                &:hover {
                    filter: invert(1);
                }
            }
        }
    }
}