header {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: flex-end;
    align-items: flex-start;
    position: fixed;
    top: 25px;
    z-index: 5;
    left: 5%;
    
    &.full {
        justify-content: space-between;
    }

    .toggleMenu {
        transform: scaleX(-1);
        cursor: pointer;
    }
}