@import '../../style/main.scss';

.albumInfosContainer {
    > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: auto;
        text-decoration: none;

        img {
            width: 100%;
            box-shadow: 5px 10px 20px rgba(black, .5);
            transition: .2s all ease-in-out;
        }

        h2 {
            margin-top: 30px;
            color: $black;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
        }

        p {
            margin-top: 5px;
            font-size: 12px;
            color: black;
            text-align: center;
        }
    }
}

ul.slick-dots {
    bottom: -50px;

    li {
        button {
            &::before {
                color: white;
                opacity: 1;
                font-size: 10px;
            } 
        }
    }
}

.slick-prev::before, .slick-next::before {
    font-size: 30px;
}

.slick-next {
    top: calc(50% - 25px);
    transform: translateY(-55%);
    right: 10%;
    z-index: 10;
    width: 50px;
    height: 50px;

    @include desktop {
        right: 21%;
        top: calc(50% - 40px);
        transform: translateY(-50%);
    }
}

.slick-prev {
    top: calc(50% - 25px);
    transform: translateY(-55%);
    left: 10%;
    z-index: 10;
    width: 50px;
    height: 50px;

    @include desktop {
        left: -10%;
        top: calc(50% - 40px);
        transform: translateY(-50%);
    }
}

@include desktop {
    .slick-slide {
        transform: translateX(-60%);
    }
}