@import '../../style/main.scss';

section.footer {
    // align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 100px;
    width: 100%;

    li {
        margin-top: 5px;
    } 

    a {
        color: black;
    }
}