$blue: #a9bcc1;
$black: #171010;
$white: #ffffff;
$red: #c94000;

$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;900&display=swap');

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: normal;
  src: local('AvenirNextHeavy'), url('../assets/fonts/AvenirNextHeavy.ttf') format('woff');
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 500;
  src: local('AvenirNext'), url('../assets/fonts/AvenirNextBold.ttf') format('woff');
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 400;
  src: local('AvenirNext'), url('../assets/fonts/AvenirNextMedium.ttf') format('woff');
}

* {
    font-family: 'Montserrat', sans-serif;
}

main {
    margin-bottom: 50px;
    margin-top: 20px;
}

.App {
  &.noscroll {
    overflow: hidden;
    width: 100%;
    height: 0;
  }
}