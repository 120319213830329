@import '../../style/main.scss';

.album {
    padding-top: 30px;
    
    .content {
        @include desktop {
            display: flex;
            width: 90%;
            margin-left: 10%;
            justify-content: space-between;
            margin-top: 100px;

            .albumMainInfos {
                margin: 0;
            }
        }
    }

    .tracklist {
        // -webkit-column-count: 2;
        // -moz-column-count: 2;
        // column-count: 2;
        margin: auto;
        margin-top: 0px;
        max-width: 90%;
        margin-top: 30px;
        display: grid; 

//   grid-template-columns: repeat(3, 33% [col-start]);

        // @media screen and (max-width: 415px) {
        //     -webkit-column-count: 1;
        //     -moz-column-count: 1;
        //     column-count: 1;
        // }

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 90%;
            margin: auto;

            @include desktop {
                display: grid;
                text-align: center;
                grid-template-columns: 1fr 1fr 1fr; 
                grid-template-rows: 1fr 1fr 1fr; 
                gap: 0px 20px;
                width: 80%;
                margin-top: -40px;
                grid-template-areas: 
                ". . ."
                ". . ."
                ". . ."; 
                text-align: left;
            }

            a {
                text-decoration: none;
                color: black;
                width: 100%;

                &:nth-child(even) {
                    color: white;
                }
            }

            li {
                margin: auto;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 16px;
                position: relative;
                text-transform: uppercase;
                width: 100%;
                margin-right: 30px;
                position: relative;
                transition: .2s all ease-in-out;

                &:hover {
                    filter: invert(1);
                }

                @include desktop {
                    margin-bottom: 20px;
                    font-size: 18px;
                    width: 100%;
                }

                span {
                    font-size: 10px;
                    display: block;
                    margin-top: 4px;
                    position: absolute;
                    bottom: -12px;
                    text-transform: initial;
                    width: 100%;
                    left: 50%;
                    transform: translateX(-50%);

                    @include desktop {
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        transform: translateX(0);
                    }
                }

                // @media screen and (min-width: 415px) {
                    
                // }

                // &.right {
                //     text-align: right;

                //     span {
                //         left: unset;
                //         right: 0;
                //     }
                // }

                // &.left {
                //     text-align: left;
                // }
                
                // @media screen and (max-width: 414px) {
                //     margin-bottom: 15px;
                //     margin-left: 50px;
                // }
            }
        }
    }
}