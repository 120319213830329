@import '../../style/main.scss';

h1 {
    font-size: 102px;
    font-weight: 500;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
    font-family: "AvenirNext";
    color: black;
    margin-top: 30px;
    text-transform: lowercase;

    span {
        // font-style: italic;
        display: block;
        font-weight: 400;
        font-size: 40px;
    }
}

.titleLink {
    color: $black;
    text-decoration: none;
}