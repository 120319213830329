@import '../../style/main.scss';

.albumMainInfos {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    margin: auto;
    margin-top: 30px;
    text-align: center;
    z-index: 10;

    @include desktop {
        max-width: 500px;
    }

    img {
        width: 100%;
        box-shadow: 5px 10px 20px rgba(black, .5);
    }

    h2 {
        margin-top: 30px;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
    }

    p {
        margin-top: 5px;
        font-size: 14px;
    }

    &.vertical {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        justify-content: center;

        img {
            width: 55%;

            @include desktop {
                width: 70%;
            } 
        }

        .texts {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            right: calc(11.25% - 25px);
            width: 50px;

            @include desktop {
                right: calc(7.5% - 25px);
            }
        }

        h2 {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            margin: auto;
        }

        p {
            margin-top: 0px;
            font-size: 12px;
        }
    }
}